<!-- 文件类型 -->
<template>
  <Container>
    <template #header>
      <el-button-group>
        <el-button type="primary" @click="onAdd">新增</el-button>
      </el-button-group>
    </template>
    <Table :head-config="listConfig.headConfig" :load-data="listConfig.dataList">
      <template v-slot:type_img="scope">
        <el-image style="width: 100px; height: 100px" :src="scope.data.row.type_img" />
      </template>
      <template v-slot:handle="scope">
        <el-button type="primary" size="mini" @click="edit(scope.data)">编辑</el-button>
        <!-- <el-button type="danger" size="mini" @click="del(scope.data)">删除</el-button> -->
      </template>
    </Table>

    <TypeView ref="typeView" :form-data.sync="form" @submit="submit" />
  </Container>
</template>

<script>
import TypeView from './components/TypeView'
import { getFileTypelist, updateFileType, getfileType } from '@/api/file'
export default {
    components: { TypeView },
    data() {
        return {
            listConfig: {
                headConfig: [ // 表头数据
                    { label: '分类名称', field: 'type_name' },
                    { label: '图片', columnType: 'slot', slotName: 'type_img' },
                    { label: '时间', field: 'time' },
                    { label: '操作', columnType: 'slot', slotName: 'handle', width: 180 }
                ],
                dataList: [], // table数据
                pageData: { total: 0, pageIndex: 1, pageSize: 10 } // 分页数据
            },
            form: {
                type_name: '',
                type_img: '',
                type_sort: ''
            }
        }
    },
    computed: { },
    watch: {},
    created() {
        this.getList()
    },
    mounted() { },
    methods: {
        getList() {
            getFileTypelist().then(res => {
                if (res.code === 0) {
                    this.listConfig.dataList = res.list
                }
            })
        },
        onAdd() {
            this.$refs.typeView.openDialog({ sign: 'add', title: '新增', switch: true })
        },
        edit(e) {
            getfileType({ id: e.row.id }).then(res => {
                if (res.code === 0) {
                    this.$refs.typeView.openDialog({ sign: 'edit', title: '编辑', switch: true })

                    this.$nextTick(function() {
                        this.form = { ...res.list }
                    })
                }
            })
        },
        async submit(val) {
            var res = await updateFileType(val.formData)
            if (res.code === 0) {
                this.$refs.typeView.resetFields()
                this.$refs.typeView.dialog.switch = false
                this.getList()
            }
        }
    }
}
</script>

