<!-- 新增文档类型 -->
<template>
  <!-- 弹窗 -->
  <Dialog :data="dialog" @submit="submit">
    <el-form ref="formData" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="类型名称:" prop="type_name"> <el-input v-model="formData.type_name" /></el-form-item>
      <el-form-item label="图片:">
        <el-upload
          class="uploader"
          :action="uploadurl"
          :headers="token"
          :show-file-list="false"
          :on-success="onSuccess"
        >
          <img v-if="formData.type_img" :src="formData.type_img" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </el-upload>
      </el-form-item>
      <el-form-item label="排序:" prop="type_sort"> <el-input v-model="formData.type_sort" /></el-form-item>
    </el-form>
  </Dialog>
</template>

<script>
export default {
    name: 'TypeView',
    props: {
        formData: { type: Object, default: () => {} }
    },
    data() {
        return {
            uploadurl: process.env.VUE_APP_BASE_API + 'admin/localUpload',
            rules: {
                name: [
                    { required: true, trigger: 'blur', message: '请输入名称！' }
                ]
            },
            dialog: {
                sign: '',
                title: '',
                switch: false,
                open: () => {}
            }
        }
    },
    computed: {
        token() { return { 'Authorization': 'Bearer ' + this.$store.getters.token } }
    },
    methods: {
        onSuccess(res, file) {
            if (res.code === 0) {
                this.formData.type_img = res.data.path
            }
        },
        submit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    this.$emit('submit', { formData: this.formData, sign: this.dialog.sign })
                } else {
                    return false
                }
            })
        },
        resetFields() {
            this.$refs.formData.resetFields()
            this.formData = {
                type_name: '',
                type_img: '',
                type_sort: ''
            }
        },
        openDialog(config = { title: '', sign: '', switch: '' }) {
            this.dialog.title = config.title
            this.dialog.sign = config.sign
            this.dialog.switch = config.switch
        }
    }
}
</script>

<style lang='scss' scoped>
::v-deep .el-form{
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }
}
</style>
