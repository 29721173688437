import request from '@/utils/request'

// 分类列表
export const getFileTypelist = (params) => {
    return request.get('admin/fileTypelist', { params })
}

// 分类详情
export const getfileType = (params) => {
    return request.get('admin/fileType', { params })
}

// 分类添加/修改
export const updateFileType = (params) => {
    return request.post('admin/fileType', params)
}

// 文档详情
export const getFileDetail = (params) => {
    return request.get('admin/fileDetail', { params })
}

// 文档列表
export const getFileList = (params) => {
    return request.get('admin/detailList', { params })
}

// 文档添加/修改
export const updateFile = (params) => {
    return request.post('admin/fileDetail', params)
}

// 文档添加/修改
export const delFile = (params) => {
    return request.post('admin/setStatusFile', params)
}
