import { render, staticRenderFns } from "./TypeView.vue?vue&type=template&id=175aaac8&scoped=true&"
import script from "./TypeView.vue?vue&type=script&lang=js&"
export * from "./TypeView.vue?vue&type=script&lang=js&"
import style0 from "./TypeView.vue?vue&type=style&index=0&id=175aaac8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "175aaac8",
  null
  
)

export default component.exports